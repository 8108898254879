import { Button, ButtonProps, useTheme } from "@mui/material"
import React, { PropsWithChildren } from "react"

type CommonButtonProps = PropsWithChildren<ButtonProps>

export const CommonButton: React.FunctionComponent<CommonButtonProps> = ({
  children,
  sx = {},
  ...props
}) => {
  const { palette } = useTheme()

  return (
    <Button
      {...props}
      color={props.color || "primary"}
      className={`inline-flex justify-center items-center relative tracking-widest ${
        props.className || ""
      }`}
      sx={{
        fontFamily: "Noto Sans, Noto Sans JP, sans-serif",
        minWidth: 96,
        padding: "5px",
        fontSize: 14,
        lineHeight: "20px",
        borderRadius: "5px",
        textTransform: "capitalize",
        "&.MuiButton-containedPrimary": {
          border: 1,
          borderColor: palette.primary.main,
          "&.Mui-disabled": {
            borderColor: "#9ca3af",
            color: "#9ca3af",
            backgroundColor: "#e5e7eb",
          },
          "&:not(.Mui-disabled):hover": {
            borderColor: palette.primary.dark,
          },
        },
        "&.MuiButton-outlinedInfo": {
          borderColor: "#6b7280",
          color: "#6b7280",
          "&:hover": { backgroundColor: "#6b7280", color: "#ffffff" },
        },
        ...sx,
      }}
    >
      {children}
    </Button>
  )
}
