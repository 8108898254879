import { AlertMessage } from "@/components/common/AlertMessage"
import { diverChain } from "@/core/constant/chain"
import { materialTheme } from "@/theme/materialTheme"
import { ThemeProvider } from "@mui/material"
import { polygon, localhost, mainnet, bsc } from "@wagmi/chains"
import React, { PropsWithChildren, useEffect } from "react"
import { configureChains, createConfig, WagmiConfig } from "wagmi"
import { publicProvider } from "wagmi/providers/public"
import "@/core/plugins/i18next"
import AlertContainer from "@/core/store/AlertContainer"
import { AlertModal } from "./common/AlertModal"
import { InjectedConnector } from "wagmi/connectors/injected"
import { MetaMaskConnector } from "wagmi/connectors/metaMask"
import { useSearchParams } from "react-router-dom"
import { ethers } from "ethers"
import { useDeviceDetect } from "@/core/hooks/useDeviceDetect"

export const Layout: React.FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const [searchParams] = useSearchParams()
  const [, setTime] = React.useState(new Date())
  const isMobileDiverWallet = searchParams.get("is_diver_wallet") === "true"
  const { isMobile } = useDeviceDetect()
  const { chains, publicClient, webSocketPublicClient } = configureChains(
    [diverChain, mainnet, polygon, localhost, bsc],
    [publicProvider()]
  )

  const testModel = sessionStorage.getItem("metamask_connect")

  useEffect(() => {
    if (isMobileDiverWallet && isMobile) {
      localStorage.setItem("is_diver_wallet", "true")
    }

    // 添加 EIP-6963 事件監聽器
    const handleEIP6963ProviderAnnounce = (event: any) => {
      if (event.detail.info.name === "DIVER Wallet") {
        const diverWallet = new ethers.providers.Web3Provider(
          event.detail.provider
        )
        window.diverWallet = diverWallet.provider
        setTime(new Date())
      }
    }

    window.addEventListener(
      "eip6963:announceProvider",
      handleEIP6963ProviderAnnounce
    )
    window.dispatchEvent(new Event("eip6963:requestProvider"))
  }, [])

  const config = createConfig({
    autoConnect: true,
    publicClient,
    webSocketPublicClient,
    connectors: () => {
      const provider = window.diverWallet || window.ethereum
      const connectors = [
        new InjectedConnector({
          chains,
          options: {
            name: "DiverWallet",
            getProvider: () =>
              provider?.isDiverWallet || isMobileDiverWallet
                ? provider
                : undefined,
            shimDisconnect: true,
          },
        }),
      ]
      if (testModel === "true") {
        connectors.push(
          new MetaMaskConnector({
            chains,
            options: {
              shimDisconnect: true,
            },
          })
        )
      }
      return connectors
    },
  })

  return (
    <ThemeProvider theme={materialTheme}>
      <WagmiConfig config={config}>
        <AlertContainer.Provider>
          <main>{children}</main>
          <AlertModal />
          <AlertMessage />
        </AlertContainer.Provider>
      </WagmiConfig>
    </ThemeProvider>
  )
}
