import { createTheme } from "@mui/material"

export const materialTheme = createTheme({
  spacing: 4,
  palette: {
    primary: {
      50: "#D6E8FF",
      100: "#2CA4DE",
      light: "#228FCE",
      300: "#1E7DBE",
      400: "#1A68AE",
      main: "#18539E",
      600: "#154C96",
      700: "#11428C",
      dark: "#0E3982",
      900: "#082970",
      contrastText: "#E3EAF3",
    },
    error: {
      main: "#d40022",
      contrastText: "#fff",
    },
    success: {
      main: "#17a025",
      contrastText: "#fff",
    },
    warning:{
      main: "#FEC51B",
      contrastText: "#fff",
    },
    text: {
      primary: "#000000",
      secondary: "rgba(63, 63, 63, 0.6)",
      disabled: "rgba(63, 63, 63, 0.38)",
    },
    background: {
      default: "#F5FAFF",
      paper: "#FFFFFF",
    },
    action: {
      active: "#9E9E9E",
      hover: "rgba(67, 131, 228, 0.2)",
      selected: "rgba(0, 0, 0, 0.08)",
      focus: "rgba(67, 131, 228, 0.3)",
    },
    grey: {},
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 768,
      lg: 1024,
      xl: 1280,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none"
          },
          "&:active": {
            boxShadow: "none"
          },
          "&:focus": {
            boxShadow: "none"
          }
        }
      }
    }
  }
})
