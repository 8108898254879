import { useRoutes, Navigate } from "react-router-dom"
import React, { ReactElement, Suspense } from "react"

const MainLayout = React.lazy(() =>
  import("../components/common/layout/MainLayout").then(({ MainLayout }) => ({
    default: MainLayout,
  }))
)

const IndexPage = React.lazy(() =>
  import("../pages").then(({ IndexPage }) => ({
    default: IndexPage,
  }))
)

const PricePage = React.lazy(() =>
  import("../pages/price").then(({ PricePage }) => ({
    default: PricePage,
  }))
)

const HistoryPage = React.lazy(() =>
  import("../pages/history").then(({ HistoryPage }) => ({
    default: HistoryPage,
  }))
)

const NotFoundPage = React.lazy(() =>
  import("../pages/404").then(({ NotFoundPage }) => ({
    default: NotFoundPage,
  }))
)

export const RouterMap = (): ReactElement | null =>
  useRoutes([
    {
      element: (
        <Suspense>
          <MainLayout />
        </Suspense>
      ),
      children: [
        {
          path: "/",
          element: (
            <Suspense>
              <IndexPage />
            </Suspense>
          ),
        },
        {
          path: "/home/*",
          element: (
            <Suspense>
              <IndexPage />
            </Suspense>
          ),
        },
        {
          path: "/price",
          element: (
            <Suspense>
              <PricePage />
            </Suspense>
          ),
        },
        {
          path: "/history",
          element: (
            <Suspense>
              <HistoryPage />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: "/404",
      element: (
        <Suspense>
          <NotFoundPage />
        </Suspense>
      ),
    },
    {
      path: "*",
      element: <Navigate to="/404" replace />,
    },
  ])
