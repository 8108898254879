import { AlertColor } from "@mui/material/Alert/Alert"
import * as React from "react"
import { PropsWithChildren, useState } from "react"
import { createContainer } from "unstated-next"
import { CommonDialogProps } from "@/components/common/CommonDialog"

export type MessageState = {
  icon?: React.ReactNode
  severity?: AlertColor
  message?: string
  display?: boolean
}

function useAlert() {
  const [state, setState] = useState<MessageState>({
    severity: "error",
    message: "",
    display: false,
  })

  const [dialogState, setDialogState] = useState<CommonDialogProps>({
    open: false,
  })

  const openDialog = (
    props: PropsWithChildren<Omit<CommonDialogProps, "open">>
  ): Promise<boolean> => {
    return new Promise(resolve => {
      if (props.buttonLeft) {
        props.buttonLeft.action = () => {
          resolve(false)
        }
      }
      if (props.buttonRight) {
        props.buttonRight.action = () => {
          resolve(true)
        }
      }
      setDialogState({
        open: true,
        close: () => {
          closeDialog()
          props.close && props.close()
        },
        ...props,
      })
    })
  }

  const closeDialog = () => {
    setDialogState({ open: false })
  }

  const pushAlert = (newState: MessageState) => {
    setState({ ...state, ...newState, display: true })
    setTimeout(() => {
      setState(preState => ({ ...preState, display: false }))
    }, 2000)
  }

  return {
    state,
    pushAlert,
    dialogState,
    closeDialog,
    openDialog,
  }
}

const AlertContainer = createContainer(useAlert)

export default AlertContainer
